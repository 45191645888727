import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { setUpNotifications } from 'reapop';
import { useDispatch, useSelector } from 'react-redux';

import Root from './containers/Root';
import SessionStore, { SS_KEYS } from './components/Stores/SessionStore';
import { AUserLogin } from './redux/actions/userActions';
import Admin from './containers/Admin';
import Kitchen from './containers/Kitchen';
import { getPriceList } from './redux/actions/priceListActions';

const App = () => {
  const token = useSelector((state) => state.user.token);

  const dispatch = useDispatch();

  setUpNotifications({
    defaultProps: {
      position: 'top-right',
      dismissible: true,
    },
  });

  useEffect(() => {
    const token = SessionStore.loadItem(SS_KEYS.TOKEN);
    const userTmp = SessionStore.loadItem(SS_KEYS.USER);
    const user = userTmp ? JSON.parse(userTmp) : undefined;
    dispatch(AUserLogin(token, user));
  }, []);

  useEffect(() => {
    if (token) {
      dispatch(getPriceList(token));
    }
  }, [token]);

  return (
    <div id="page">
      <Switch>
        <Route path="/admin" component={Admin} />
        <Route path="/kitchen" component={Kitchen} />
        <Route path="/" component={Root} />
      </Switch>
    </div>
  );
};

export default App;
