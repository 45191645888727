import React from 'react';
import { Image } from 'react-bootstrap';
import { startOfDay } from 'date-fns';
import { useSelector } from 'react-redux';

import onsite from '../../assets/images/onsite_green.png';
import takeout from '../../assets/images/takeout_green.png';
import { __API_URL__ } from '../../constants';
import meat from '../../assets/images/meat.png';
import { getFoodPrice, getFoodReducedPrice } from '../../utils/priceListHelper';
import { getUTCDate } from '../../utils';

const Food = (props) => {
  const { data, disabled, onsiteCount, takeoutCount, ordered, orderedCount, takeoutPickedCount, nontakeoutPickedCount, image, action, day } = props;

  const user = useSelector((state) => state.user.user);
  const priceList = useSelector((state) => state.priceList.items);
  const packagingId = useSelector((state) => state.priceList.packagingId);

  const price = getFoodPrice(data.id, day || getUTCDate(startOfDay(new Date())).toISOString(), priceList, packagingId);
  const reducedPrice = getFoodReducedPrice(data.id, day || getUTCDate(startOfDay(new Date())).toISOString(), priceList, packagingId);

  const checked = onsiteCount > 0 || takeoutCount > 0;
  const className = `food${disabled ? ' disabled' : ''}`;
  return (
    <div className={`d-flex flex-column justify-content-center align-items-center ${className}`} onClick={action}>
      {!disabled && ordered && <span className="ordered" />}
      {!disabled && checked && <span className="checked" />}
      <div className="counts">
        {!disabled && orderedCount > 0 && <span className="count">{`${orderedCount}x`}</span>}
        {!disabled && onsiteCount > 0 && (
          <span className="count">
            {`${onsiteCount}x`} <img src={onsite} alt="" />
          </span>
        )}
        {!disabled && takeoutCount > 0 && (
          <span className="count">
            {`${takeoutCount}x`} <img src={takeout} alt="" />
          </span>
        )}
        {disabled && takeoutPickedCount && (
          <span className="stats">
            {takeoutPickedCount} <img src={takeout} alt="" />
          </span>
        )}
        {disabled && nontakeoutPickedCount && (
          <span className="stats">
            {nontakeoutPickedCount} <img src={onsite} alt="" />
          </span>
        )}
      </div>
      {image ? (
        <Image fluid src={image} alt="food" className="food-image" />
      ) : (
        <Image fluid src={data.picture ? `${__API_URL__}/${data.picture}` : meat} alt="food" className="food-image" />
      )}
      {price > 0 ? (
        <div className="d-flex flex-row w-100 justify-content-between align-items-center">
          <div className="text col-xs-8">{data.longName}</div>
          <div className="d-flex flex-column justify-content-end align-items-end">
            <div className="price col-xs-3">{`${price}Kč`}</div>
            {user && user.cardId.match(/MOV/) && reducedPrice ? <div className="price col-xs-3">{`${reducedPrice}Kč`}</div> : null}
          </div>
        </div>
      ) : (
        <div className="text">{data.longName}</div>
      )}
    </div>
  );
};

export default Food;
