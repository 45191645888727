import React from 'react';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons/faSignOutAlt';
import { faListAlt } from '@fortawesome/free-solid-svg-icons/faListAlt';
import { useDispatch, useSelector } from 'react-redux';

import GridSection from '../../../components/Section/GridSection';
import MyButton from '../../../components/MyButton/index';
import { userLogout } from '../../../redux/actions/userActions';

const Home = () => {
  const role = useSelector((state) => state.user.user.role);
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(userLogout());
  };

  return (
    <div>
      <GridSection heading="ADMINISTRACE">
        <MyButton variant="danger" icon={faSignOutAlt} text="ODHLÁSIT SE" action={handleLogout} />
      </GridSection>
      <GridSection heading="VÝPISY Z DATABÁZE">
        {role === 'admin' ? <MyButton link="/admin/allergens" variant="outline-dark" icon={faListAlt} text="SEZNAM ALERGENŮ" /> : null}
        {role === 'admin' ? <MyButton link="/admin/foods" variant="outline-dark" icon={faListAlt} text="SEZNAM JÍDEL" /> : null}
        {role === 'admin' ? <MyButton link="/admin/ratings" variant="outline-dark" icon={faListAlt} text="SEZNAM HODNOCENÍ" /> : null}
        {role === 'admin' ? <MyButton link="/admin/menus" variant="outline-dark" icon={faListAlt} text="SEZNAM MENÍČEK" /> : null}
        {['admin', 'cook'].includes(role) ? <MyButton link="/admin/orders" variant="outline-dark" icon={faListAlt} text="SEZNAM OBJEDNÁVEK" /> : null}
        {role === 'admin' ? <MyButton link="/admin/users" variant="outline-dark" icon={faListAlt} text="SEZNAM UŽIVATELŮ" /> : null}
        {role === 'admin' ? <MyButton link="/admin/creditLog" variant="outline-dark" icon={faListAlt} text="VÝPIS ZMĚN KREDITU" /> : null}
        {role === 'admin' ? <MyButton link="/admin/priceList" variant="outline-dark" icon={faListAlt} text="CENÍK" /> : null}
      </GridSection>
    </div>
  );
};

export default Home;
