import axios from 'axios';
import { notify } from 'reapop';

import { ENDPOINTS } from '../../constants';
import {
  PRICELIST_ALL,
  PRICELIST_CREATE,
  PRICELIST_CREATE_MULTIPLE,
  PRICELIST_FAILURE,
  PRICELIST_LOADING,
  PRICELIST_ONE,
  PRICELIST_REMOVE,
  PRICELIST_UPDATE,
} from '../types/priceListTypes';
import { processPriceList } from '../helpers/priceListHelpers';
import { getCorrectDate, getCorrectDateEnd } from '../../utils';

const APriceListLoading = (name) => ({
  type: PRICELIST_LOADING,
  name,
});

const APriceListFailure = (name, error) => ({
  type: PRICELIST_FAILURE,
  name,
  error,
});

const AGetPriceList = (priceList) => ({
  type: PRICELIST_ALL,
  priceList,
});

const AGetPriceListItem = (priceListItem) => ({
  type: PRICELIST_ONE,
  priceListItem,
});

const ACreatePriceListItem = (priceListItem) => ({
  type: PRICELIST_CREATE,
  priceListItem,
});

const ACreatePriceList = (priceList) => ({
  type: PRICELIST_CREATE_MULTIPLE,
  priceList,
});

const AUpdatePriceListItem = (priceListItem) => ({
  type: PRICELIST_UPDATE,
  priceListItem,
});

const ARemovePriceListItem = (priceListItem) => ({
  type: PRICELIST_REMOVE,
  priceListItem,
});

export const getPriceList = (token) => async (dispatch) => {
  const actionName = 'priceListAll';
  try {
    dispatch(APriceListLoading(actionName));
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.get(ENDPOINTS.priceList, config);
    const { data } = response;
    const priceList = processPriceList(data);
    dispatch(AGetPriceList(priceList));
  } catch (e) {
    dispatch(APriceListFailure(actionName));
    console.error(e);
  }
};

export const getPriceListItem = (token, priceListItemId) => async (dispatch) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.get(`${ENDPOINTS.priceList}/${priceListItemId}`, config);
    const { data } = response;
    dispatch(AGetPriceListItem(data));
  } catch (e) {
    dispatch(
      notify({
        title: 'Získání položky ceníku',
        message: 'Nepodařilo se získat informace o položce ceníku',
        status: 'error',
        dismissAfter: 3000,
      }),
    );
  }
};

export const createPriceListMultiple = (token, values) => async (dispatch) => {
  const actionName = 'priceListCreateMultiple';
  dispatch(APriceListLoading(actionName));
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const postData = {
    ...values,
    from: getCorrectDate(values.from).toISOString(),
    to: values.to ? getCorrectDateEnd(values.to).toISOString() : undefined,
  };
  try {
    const response = await axios.post(`${ENDPOINTS.priceList}/multiple`, postData, config);
    const { data } = response;
    dispatch(ACreatePriceList(processPriceList(data)));
    dispatch(
      notify({
        title: 'Hromadná aktualizace ceníku',
        message: `Nové ceny jídel byly nastaveny pro všechna jídla.`,
        status: 'success',
        dismissAfter: 3000,
      }),
    );
  } catch (e) {
    dispatch(APriceListFailure(actionName, e));
    dispatch(
      notify({
        title: 'Hromadná aktualizace ceníku',
        message: `Aktualizace cen se nezdařila.`,
        status: 'error',
        dismissAfter: 3000,
      }),
    );
  }
};

export const createPriceListItem = (token, values) => async (dispatch) => {
  const actionName = 'priceListCreate';
  dispatch(APriceListLoading(actionName));
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const postData = {
    ...values,
    from: getCorrectDate(values.from).toISOString(),
    to: values.to ? getCorrectDateEnd(values.to).toISOString() : undefined,
  };
  try {
    const response = await axios.post(ENDPOINTS.priceList, postData, config);
    const { data } = response;
    dispatch(ACreatePriceListItem(data));
    dispatch(
      notify({
        title: 'Přídání položky ceníku',
        message: `Položka ceníku byla přidána.`,
        status: 'success',
        dismissAfter: 3000,
      }),
    );
  } catch (e) {
    dispatch(APriceListFailure(actionName, e));
    dispatch(
      notify({
        title: 'Přídání položky ceníku',
        message: `Položku ceníku se nepodařilo přidat.`,
        status: 'error',
        dismissAfter: 3000,
      }),
    );
  }
};

export const updatePriceListItem = (token, priceListItemId, values) => async (dispatch) => {
  const actionName = 'priceListUpdate';
  dispatch(APriceListLoading(actionName));
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await axios.put(`${ENDPOINTS.priceList}/${priceListItemId}`, values, config);
    const { data } = response;
    dispatch(AUpdatePriceListItem(data));
    dispatch(
      notify({
        title: 'Úprava položky ceníku',
        message: `Položka ceníku byla upravena.`,
        status: 'success',
        dismissAfter: 3000,
      }),
    );
  } catch (e) {
    dispatch(APriceListFailure(actionName, e));
    dispatch(
      notify({
        title: 'Úprava položky ceníku',
        message: 'Položku ceníku se nepodařilo upravit.',
        status: 'error',
        dismissAfter: 3000,
      }),
    );
  }
};

export const removePriceListItem = (token, priceListItem) => async (dispatch) => {
  const actionName = 'priceListRemove';
  dispatch(APriceListLoading(actionName));
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    await axios.delete(`${ENDPOINTS.priceList}/${priceListItem.id}`, config);
    dispatch(ARemovePriceListItem(priceListItem));
    dispatch(
      notify({
        title: 'Odstranění položky ceníku',
        message: `Položka ceníku byla odstraněna.`,
        status: 'success',
        dismissAfter: 3000,
      }),
    );
  } catch (e) {
    dispatch(APriceListFailure(actionName, e));
    dispatch(
      notify({
        title: 'Odstranění položky ceníku',
        message: `Položku ceníku se nepodařilo odstranit.`,
        status: 'error',
        dismissAfter: 3000,
      }),
    );
  }
};
