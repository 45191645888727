import React, { useEffect, useState } from 'react';
import moment from 'moment';
import 'moment/locale/cs';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from 'react-bootstrap';
import { faHandPointLeft } from '@fortawesome/free-solid-svg-icons/faHandPointLeft';
import { useHistory } from 'react-router-dom';

import GridSection from '../../../components/Section/GridSection';
import MyButton from '../../../components/MyButton/index';
import Loading from '../../../components/Loading/index';
import Section from '../../../components/Section/Section';
import WeekChanger from '../../../components/WeekChanger/index';
import { getOrders } from '../../../redux/actions/ordersActions';

const OrdersList = () => {
  const history = useHistory();

  const loading = useSelector((state) => state.admin.orders.loadings.all);
  const orders = useSelector((state) => state.admin.orders.activeOrders);

  const dispatch = useDispatch();

  const [from, setFrom] = useState(moment().startOf('week'));
  const [to, setTo] = useState(moment().endOf('week'));

  useEffect(() => {
    if (!loading) {
      dispatch(getOrders(from, to));
    }
  }, []);

  const handlePreviousWeek = () => {
    setFrom(from.subtract(1, 'week'));
    setTo(to.subtract(1, 'week'));
    dispatch(getOrders(from, to));
  };

  const handleNextWeek = () => {
    setFrom(from.add(1, 'week'));
    setTo(to.add(1, 'week'));
    dispatch(getOrders(from, to));
  };

  return (
    <div>
      <GridSection heading="ADMINISTRACE">
        <MyButton link="/admin" variant="outline-dark" icon={faHandPointLeft} text="ZPĚT" />
      </GridSection>
      <Section heading="SEZNAM OBJEDNÁVEK">
        <WeekChanger
          text={`${moment(from).week()}. týden: ${moment(from).format('LL')} - ${moment(to).format('LL')}`}
          previousWeek={handlePreviousWeek}
          nextWeek={handleNextWeek}
        />
        {loading ? (
          <Loading />
        ) : (
          <Table responsive hover className="spanned">
            <thead>
              <tr>
                <th>Datum</th>
                <th>Den</th>
                <th>Jídlo</th>
                <th>Konzumace na místě</th>
                <th>Jídlo s sebou</th>
                <th>Celkem</th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order) =>
                order.foods
                  .sort((a, b) => {
                    const sortTypes = ['permanent', 'breakfast', 'lunch', 'dinner'];
                    const typeSorted = sortTypes.indexOf(a.type) - sortTypes.indexOf(b.type);
                    return typeSorted ? typeSorted : a.longName.localeCompare(b.longName);
                  })
                  .map((food, index) => (
                    <tr key={`order_key_${order.day}_${food.id}`} onClick={() => history.push(`/admin/orders/dayPreview/${order.day}`)}>
                      {index === 0 && (
                        <td className="first" rowSpan={order.foods.length}>
                          {moment(order.day).format('D.M.')}
                        </td>
                      )}
                      {index === 0 && (
                        <td className="first" rowSpan={order.foods.length}>
                          {moment(order.day).format('dddd').toUpperCase()}
                        </td>
                      )}
                      <td className={index === 0 ? 'first' : ''}>{food.name}</td>
                      <td className={index === 0 ? 'first' : ''}>{`${food.onsiteCount}x`}</td>
                      <td className={index === 0 ? 'first' : ''}>{`${food.takeoutCount}x`}</td>
                      <td className={index === 0 ? 'first' : ''}>{`${food.onsiteCount + food.takeoutCount}x`}</td>
                    </tr>
                  )),
              )}
            </tbody>
          </Table>
        )}
      </Section>
    </div>
  );
};

export default OrdersList;
