import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { faHandPointLeft } from '@fortawesome/free-solid-svg-icons/faHandPointLeft';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons/faPlusCircle';
import { Tab, Tabs } from 'react-bootstrap';

import { getPriceList } from '../../../redux/actions/priceListActions';
import Section from '../../../components/Section/Section';
import Loading from '../../../components/Loading/index';
import MyButton from '../../../components/MyButton/index';
import GridSection from '../../../components/Section/GridSection';
import PriceListTable from '../../../components/PriceListTable';

const PriceList = () => {
  const token = useSelector((state) => state.user.token);
  const loading = useSelector((state) => state.admin.priceList.loadings.priceListAll);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!loading) {
      dispatch(getPriceList(token));
    }
  }, []);

  return (
    <div>
      <GridSection heading="ADMINISTRACE">
        <MyButton link="/admin" variant="outline-dark" icon={faHandPointLeft} text="ZPĚT" />
        <MyButton link="/admin/priceList/add" variant="success" icon={faPlusCircle} text="PŘIDAT CENU" />
        <MyButton link="/admin/priceList/addMultiple" variant="success" icon={faPlusCircle} text="PŘIDAT CENU HROMADNĚ" />
      </GridSection>
      <Section heading="SEZNAM POLOŽEK CENIKU">
        {loading ? (
          <Loading />
        ) : (
          <Tabs>
            <Tab eventKey="all" title="Všechna jídla">
              <PriceListTable type="all" />
            </Tab>
            <Tab eventKey="permanent" title="Stálá nabídka">
              <PriceListTable type="permanent" />
            </Tab>
            <Tab eventKey="breakfast" title="Snídaně">
              <PriceListTable type="breakfast" />
            </Tab>
            <Tab eventKey="lunch" title="Obědy">
              <PriceListTable type="lunch" />
            </Tab>
            <Tab eventKey="dinner" title="Večeře">
              <PriceListTable type="dinner" />
            </Tab>
          </Tabs>
        )}
      </Section>
    </div>
  );
};

export default PriceList;
