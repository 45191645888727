import React, { useEffect, useMemo } from 'react';
import { Button, Col, Form, FormGroup } from 'react-bootstrap';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';

import { createPriceListItem } from '../../../redux/actions/priceListActions';
import { getCorrectDate, getFoodType } from '../../../utils';
import { getFoods } from '../../../redux/actions/foodsActions';
import Loading from '../../../components/Loading';

import PriceListItemValidationSchema from './validation';

const PriceListItemForm = (props) => {
  const { priceListItem, buttonText, onSubmit } = props;

  const token = useSelector((state) => state.user.token);
  const loadingFood = useSelector((state) => state.admin.foods.loadings.all);
  const loadingAdd = useSelector((state) => state.admin.priceList.loadings.priceListCreate);
  const loadingEdit = useSelector((state) => state.admin.priceList.loadings.priceListUpdate);
  const foods = useSelector((state) => state.admin.foods.items);

  const dispatch = useDispatch();

  const lastPrice = useMemo(() => {
    if (priceListItem) {
      const priceListActual = priceListItem.priceList.find(
        (p) => (new Date(p.from) <= new Date() && new Date() <= new Date(p.to)) || (new Date(p.from) <= new Date() && !p.to),
      );
      return priceListActual?.price || 0;
    } else {
      return 0;
    }
  }, [priceListItem]);

  useEffect(() => {
    dispatch(getFoods());
  }, [dispatch]);

  const handleSubmit = (values) => {
    dispatch(createPriceListItem(token, values));
    onSubmit();
  };

  if (loadingFood) return <Loading />;

  const initialValues = {
    foodId: priceListItem ? priceListItem.id : undefined,
    from: getCorrectDate(new Date()),
    to: undefined,
    price: lastPrice || 0,
  };

  return (
    <Formik initialValues={initialValues} validationSchema={PriceListItemValidationSchema} onSubmit={handleSubmit}>
      {({ values, errors, touched, handleChange, setFieldValue, setFieldError, handleSubmit }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Col xs={{ span: 6, offset: 3 }}>
            <Form.Group>
              <Form.Label>Jídlo *</Form.Label>
              <Form.Control
                as="select"
                name="foodId"
                value={!values.foodId || values.foodId === '' ? 'Vyberte jídlo' : values.foodId}
                placeholder="Vyberte jídlo"
                onChange={(e) => {
                  handleChange(e);
                }}
                isInvalid={touched.foodId && errors.foodId}>
                <option value="">Vyberte jídlo</option>
                {Object.values(foods)
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((food) => (
                    <option key={food.id} value={food.id}>{`${food.name} (${getFoodType(food.type)})`}</option>
                  ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">{errors.foodId}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Datum od *</Form.Label>
              <DatePicker
                className="form-control"
                selected={values.from}
                onChange={(value) => {
                  const day = getCorrectDate(value);
                  setFieldError('from', !day ? 'Datum od musí být vyplněno!' : undefined);
                  setFieldValue('from', day);
                }}
                locale="cs"
                dateFormat="dd. MM. yyyy"
              />
              <Form.Control.Feedback type="invalid">{errors.from}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Datum do</Form.Label>
              <DatePicker
                className="form-control"
                selected={values.to}
                onChange={(value) => {
                  const to = getCorrectDate(value);
                  setFieldError('to', !to ? 'Datum do musí být vyplněno!' : undefined);
                  setFieldValue('to', to);
                }}
                locale="cs"
                dateFormat="dd. MM. yyyy"
              />
              <Form.Control.Feedback type="invalid">{errors.to}</Form.Control.Feedback>
            </Form.Group>
            <FormGroup>
              <Form.Label>Cena</Form.Label>
              <Form.Control
                type="number"
                name="price"
                value={values.price}
                placeholder="Zadejte cenu"
                onChange={handleChange}
                isInvalid={touched.price && errors.price}
              />
              <Form.Control.Feedback type="invalid">{errors.price}</Form.Control.Feedback>
            </FormGroup>
            <FormGroup>
              <Form.Label>Zvýhodněná cena</Form.Label>
              <Form.Control
                type="number"
                name="reducedPrice"
                value={values.reducedPrice}
                placeholder="Zadejte zvýhodněnou cenu"
                onChange={handleChange}
                isInvalid={touched.reducedPrice && errors.reducedPrice}
              />
              <Form.Control.Feedback type="invalid">{errors.reducedPrice}</Form.Control.Feedback>
            </FormGroup>
            <Form.Group>
              <Button variant="outline-dark" disabled={loadingAdd || loadingEdit} type="submit">
                {buttonText}
              </Button>
            </Form.Group>
          </Col>
        </Form>
      )}
    </Formik>
  );
};

export default PriceListItemForm;
