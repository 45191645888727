import axios from 'axios';
import { notify } from 'reapop';

import { ENDPOINTS } from '../../constants';
import { MENUS_ALL, MENUS_CREATE, MENUS_FAILURE, MENUS_LOADING, MENUS_ONE, MENUS_REMOVE, MENUS_UPDATE } from '../types/menusTypes';
import { processMenus } from '../helpers/menusHelpers';
import { getCorrectDate } from '../../utils';

const AMenusLoading = (name) => ({
  type: MENUS_LOADING,
  name,
});

const AMenusFailure = (name, error) => ({
  type: MENUS_FAILURE,
  name,
  error,
});

const AGetMenus = (menus) => ({
  type: MENUS_ALL,
  menus,
});

const AGetMenu = (menu) => ({
  type: MENUS_ONE,
  menu,
});

const ACreateMenu = (menu) => ({
  type: MENUS_CREATE,
  menu,
});

const AUpdateMenu = (menu) => ({
  type: MENUS_UPDATE,
  menu,
});

const ARemoveMenu = (menu) => ({
  type: MENUS_REMOVE,
  menu,
});

export const getMenus = (fromDate, toDate) => async (dispatch) => {
  const actionName = 'all';
  dispatch(AMenusLoading(actionName));

  const from = getCorrectDate(fromDate).toISOString();
  const to = getCorrectDate(toDate).toISOString();

  const config = {
    params: {
      from,
      to,
    },
  };
  try {
    const response = await axios.get(ENDPOINTS.menus, config);
    const { data } = response;
    const menus = processMenus(data);
    dispatch(AGetMenus(menus));
  } catch (e) {
    dispatch(AMenusFailure(actionName, e));
    dispatch(
      notify({
        title: 'Nepodařilo se získat seznam menu',
        message: e.response.data.message,
        status: 'error',
        dismissAfter: 3000,
      }),
    );
  }
};

export const getMenu = (menuId) => async (dispatch) => {
  const actionName = 'one';
  dispatch(AMenusLoading(actionName));
  try {
    const response = await axios.get(`${ENDPOINTS.menus}/${menuId}`);
    const { data } = response;
    dispatch(AGetMenu(data));
  } catch (e) {
    dispatch(AMenusFailure(actionName, e));
    dispatch(
      notify({
        title: 'Nepodařilo se získat informace o menu',
        message: e.response.data.message,
        status: 'error',
        dismissAfter: 3000,
      }),
    );
  }
};

export const createMenu = (token, values) => async (dispatch) => {
  const actionName = 'create';
  dispatch(AMenusLoading(actionName));

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const sendData = {
    ...values,
    day: values.day.toISOString(),
  };

  try {
    const response = await axios.post(ENDPOINTS.menus, sendData, config);
    const { data } = response;
    dispatch(ACreateMenu(data));
    dispatch(
      notify({
        title: 'Menu bylo přidáno',
        status: 'success',
        dismissAfter: 3000,
      }),
    );
  } catch (e) {
    dispatch(AMenusFailure(actionName, e));
    dispatch(
      notify({
        title: 'Menu se nepodařilo přidat',
        message: e.response.data.message,
        status: 'error',
        dismissAfter: 3000,
      }),
    );
  }
};

export const updateMenu = (token, menuId, values) => async (dispatch) => {
  const actionName = 'update';
  dispatch(AMenusLoading(actionName));
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const sendData = {
    ...values,
    day: values.day.toISOString(),
  };

  try {
    const response = await axios.put(`${ENDPOINTS.menus}/${menuId}`, sendData, config);
    const { data } = response;
    dispatch(AUpdateMenu(data));
    dispatch(
      notify({
        title: `Menu bylo upraveno`,
        status: 'success',
        dismissAfter: 3000,
      }),
    );
  } catch (e) {
    dispatch(AMenusFailure(actionName, e));
    dispatch(
      notify({
        title: 'Menu se nepodařilo upravit',
        message: e.response.data.message,
        status: 'error',
        dismissAfter: 3000,
      }),
    );
  }
};

export const removeMenu = (token, menu) => async (dispatch) => {
  const actionName = 'remove';
  dispatch(AMenusLoading(actionName));
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    await axios.delete(`${ENDPOINTS.menus}/${menu.id}`, config);
    dispatch(ARemoveMenu(menu));
    dispatch(
      notify({
        title: `Menu bylo odstraněno`,
        status: 'success',
        dismissAfter: 3000,
      }),
    );
  } catch (e) {
    dispatch(AMenusFailure(actionName, e));
    dispatch(
      notify({
        title: 'Menu se nepodařilo odstranit',
        message: e.response.data.message,
        status: 'error',
        dismissAfter: 3000,
      }),
    );
  }
};
