import axios from 'axios';

import { ENDPOINTS } from './index';

export const getPriceList = async (token, order) => {
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await axios.get(`${ENDPOINTS.priceList}/priceListByOrder/${order.id}`, config);
    return response.data;
  } catch (e) {
    console.error(e);
    return;
  }
};

export default getPriceList;
