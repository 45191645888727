import React, { useEffect, useState } from 'react';
import { faHandPointLeft } from '@fortawesome/free-solid-svg-icons/faHandPointLeft';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import GridSection from '../../../components/Section/GridSection';
import Section from '../../../components/Section/Section';
import MyButton from '../../../components/MyButton/index';
import { getPriceListItem } from '../../../redux/actions/priceListActions';
import PriceListItemForm from '../../../forms/PriceListForms/PriceListForm';

const PriceListForms = () => {
  const match = useRouteMatch();
  const history = useHistory();

  const token = useSelector((state) => state.user.token);
  const loading = useSelector((state) => state.admin.priceList.loadings.priceListOne);
  const priceListItem = useSelector((state) => state.admin.priceList.items[match.params.id]);
  const loadingAdd = useSelector((state) => state.admin.priceList.loadings.priceListCreate);
  const errorAdd = useSelector((state) => state.admin.priceList.errors.priceListCreate);

  const dispatch = useDispatch();

  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    if (match.params.id && !loading) {
      dispatch(getPriceListItem(token, match.params.id));
    }
  }, []);

  useEffect(() => {
    if (isSubmitted && !loadingAdd && !errorAdd) {
      setIsSubmitted(false);
      if (match.params.id) {
        history.replace(`/admin/priceList/preview/${match.params.id}`);
      } else {
        history.replace('/admin/priceList');
      }
    }
  }, [loadingAdd, errorAdd]);

  return (
    <div>
      <GridSection heading="ADMINISTRACE">
        <MyButton variant="outline-dark" icon={faHandPointLeft} text="ZPĚT" action={() => history.goBack()} />
      </GridSection>
      <Section heading={priceListItem ? 'Formulář pro úpravu položky ceníku' : 'Formulář pro přidání položky ceníku'}>
        <PriceListItemForm
          priceListItem={priceListItem}
          buttonText={priceListItem ? 'Upravit položku ceníku' : 'Přidat položku ceníku'}
          onSubmit={() => setIsSubmitted(true)}
        />
      </Section>
    </div>
  );
};

export default PriceListForms;
