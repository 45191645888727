import React from 'react';
import { useSelector } from 'react-redux';

import PriceListTableContent from './PriceListTableContent';

const PriceListTablePermanent = () => {
  const priceList = useSelector((state) => state.admin.priceList.items);

  const priceListSorted = Object.values(priceList)
    .filter((food) => food.permanent === true)
    .sort((a, b) => a.name.localeCompare(b.name));

  return <PriceListTableContent priceList={priceListSorted} />;
};

export default PriceListTablePermanent;
