import React, { useEffect, useState } from 'react';
import { Button, Modal, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { remove as removeDiacritics } from 'diacritics';
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { faMinusCircle } from '@fortawesome/free-solid-svg-icons/faMinusCircle';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons/faPlusCircle';

import { removeFood, updateFood } from '../../redux/actions/foodsActions';
import FoodFilter from '../Filters/FoodFilter';
import { getFoodType } from '../../utils';

const FoodTableContent = (props) => {
  const { foods, showType } = props;

  const history = useHistory();

  const token = useSelector((state) => state.user.token);
  const loadingUpdate = useSelector((state) => state.admin.foods.loadings.update);
  const loadingRemove = useSelector((state) => state.admin.foods.loadings.remove);

  const dispatch = useDispatch();

  const [filteredFoods, setFilteredFoods] = useState(foods);
  const [selectedFood, setSelectedFood] = useState();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    handleFilter('');
  }, [foods]);

  const handleFilter = (search) => {
    const filtered = foods.filter((food) => {
      return removeDiacritics(food.name).toLowerCase().includes(removeDiacritics(search).toLowerCase());
    });
    setFilteredFoods(filtered);
  };

  const handleRemove = (food) => {
    if (!loadingRemove) {
      dispatch(removeFood(token, food));
      setShowModal(false);
      setSelectedFood(undefined);
    }
  };

  const handleChangeDisabled = (food) => {
    if (!loadingUpdate) {
      dispatch(updateFood(token, food.id, { ...food, disabled: !food.disabled }));
    }
  };

  const tooltipView = <Tooltip id="tooltip-view">Zobrazit jídlo</Tooltip>;
  const tooltipEdit = <Tooltip id="tooltip-edit">Upravit jídlo</Tooltip>;
  const tooltipRemove = <Tooltip id="tooltip-remove">Odstranit jídlo</Tooltip>;

  return (
    <>
      <FoodFilter handleFilter={handleFilter} />
      <Table responsive striped>
        <thead>
          <tr>
            <th>Název jídla</th>
            {showType ? <th style={{ textAlign: 'center' }}>Typ</th> : null}
            <th style={{ textAlign: 'center' }}>Počet výskytů na jídelníčku</th>
            <th style={{ textAlign: 'center' }}>Celkový počet objednávek</th>
            <th style={{ textAlign: 'right' }}>Možnosti</th>
          </tr>
        </thead>
        <tbody>
          {filteredFoods.length ? (
            filteredFoods.map((food) => (
              <tr key={food.id}>
                <td>{food.name}</td>
                {showType ? <td style={{ textAlign: 'center' }}>{getFoodType(food.type)}</td> : null}
                <td style={{ textAlign: 'center' }}>{food.onTheMenu}</td>
                <td style={{ textAlign: 'center' }}>{food.orderedTimes}</td>
                <td style={{ textAlign: 'right' }}>
                  <OverlayTrigger placement="top" overlay={tooltipView}>
                    <Button variant="light" onClick={() => history.push(`/admin/foods/preview/${food.id}`)}>
                      <FontAwesomeIcon icon={faEye} />
                    </Button>
                  </OverlayTrigger>
                  <OverlayTrigger placement="top" overlay={tooltipEdit}>
                    <Button variant="light" onClick={() => history.push(`/admin/foods/edit/${food.id}`)}>
                      <FontAwesomeIcon icon={faEdit} />
                    </Button>
                  </OverlayTrigger>
                  {food.permanent ? (
                    <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-disabled">{food.disabled ? 'Povolit jídlo' : 'Zakázat jídlo'}</Tooltip>}>
                      <Button
                        variant={food.disabled ? 'success' : 'danger'}
                        onClick={() => {
                          handleChangeDisabled(food);
                        }}>
                        <FontAwesomeIcon icon={food.disabled ? faPlusCircle : faMinusCircle} />
                      </Button>
                    </OverlayTrigger>
                  ) : (
                    <OverlayTrigger placement="top" overlay={tooltipRemove}>
                      <Button
                        variant="danger"
                        onClick={() => {
                          setSelectedFood(food);
                          setShowModal(true);
                        }}>
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                    </OverlayTrigger>
                  )}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={4} className="text-center">
                Žádná jídla k dispozici.
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      {selectedFood && (
        <Modal show={showModal} size="lg" onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Odstranění jídla</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{`Opravdu chcete odstranit jídlo ${selectedFood.longName}?`}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={() => handleRemove(selectedFood)}>
              Odstranit
            </Button>
            <Button variant="outline-dark" onClick={() => setShowModal(false)}>
              Zavřít
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default FoodTableContent;
