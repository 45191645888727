import {
  PRICELIST_ALL,
  PRICELIST_CREATE,
  PRICELIST_CREATE_MULTIPLE,
  PRICELIST_FAILURE,
  PRICELIST_LOADING,
  PRICELIST_ONE,
  PRICELIST_REMOVE,
  PRICELIST_UPDATE,
} from '../../types/priceListTypes';

const INITIAL_STATE = {
  loadings: {},
  errors: {},
  items: {},
};

const priceListReducer = (currentState = INITIAL_STATE, action) => {
  let newState;
  switch (action.type) {
    case PRICELIST_LOADING:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          [action.name]: true,
        },
      };
    case PRICELIST_FAILURE:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          [action.name]: false,
        },
        errors: {
          ...currentState.errors,
          [action.name]: action.error,
        },
      };
    case PRICELIST_ALL:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          priceListAll: false,
        },
        errors: {
          ...currentState.errors,
          priceListAll: undefined,
        },
        items: {
          ...currentState.items,
          ...action.priceList,
        },
      };
    case PRICELIST_ONE:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          priceListOne: false,
        },
        errors: {
          ...currentState.errors,
          priceListOne: undefined,
        },
        items: {
          ...currentState.items,
          [action.priceListItem.id]: {
            ...currentState.items[action.priceListItem.id],
            ...action.priceListItem,
          },
        },
      };
    case PRICELIST_CREATE:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          priceListCreate: false,
        },
        errors: {
          ...currentState.errors,
          priceListCreate: undefined,
        },
        items: {
          ...currentState.items,
          [action.priceListItem.id]: action.priceListItem,
        },
      };
    case PRICELIST_CREATE_MULTIPLE:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          priceListCreateMultiple: false,
        },
        errors: {
          ...currentState.errors,
          priceListCreateMultiple: undefined,
        },
        items: {
          ...currentState.items,
          ...action.priceList,
        },
      };
    case PRICELIST_UPDATE:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          priceListUpdate: false,
        },
        errors: {
          ...currentState.errors,
          priceListUpdate: undefined,
        },
        items: {
          ...currentState.items,
          [action.priceListItem.id]: {
            ...currentState.items[action.priceListItem.id],
            ...action.priceListItem,
          },
        },
      };
    case PRICELIST_REMOVE:
      newState = {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          priceListRemove: false,
        },
        errors: {
          ...currentState.errors,
          priceListRemove: undefined,
        },
        items: {
          ...currentState.items,
        },
      };
      delete newState.items[action.priceListItem.id];
      return newState;
    default:
      return currentState;
  }
};

export default priceListReducer;
