import React from 'react';
import { useSelector } from 'react-redux';

import PriceListTableContent from './PriceListTableContent';

const PriceListTableByType = (props) => {
  const { type } = props;

  const priceList = useSelector((state) => state.admin.priceList.items);

  const priceListSorted = Object.values(priceList)
    .filter((food) => food.type === type)
    .sort((a, b) => a.name.localeCompare(b.name));

  return <PriceListTableContent priceList={priceListSorted} />;
};

export default PriceListTableByType;
