import axios from 'axios';
import { notify } from 'reapop';

import { ENDPOINTS } from '../../constants';
import { USER_FAILURE, USER_LOADING, USER_LOGIN, USER_LOGOUT } from '../types/userTypes';
import SessionStore, { SS_KEYS } from '../../components/Stores/SessionStore';

const AUserLoading = (name) => ({
  type: USER_LOADING,
  name,
});

const AUserFailure = (name, error) => ({
  type: USER_FAILURE,
  name,
  error,
});

export const AUserLogin = (token, user) => ({
  type: USER_LOGIN,
  token,
  user,
});

export const AUserLogout = () => ({
  type: USER_LOGOUT,
});

export const adminLogin = (values) => async (dispatch) => {
  const actionName = 'userLogin';
  dispatch(AUserLoading(actionName));
  try {
    const response = await axios.post(`${ENDPOINTS.auth}`, { email: values.email, password: values.password });
    const {
      data: { token, user },
    } = response;
    if (['admin', 'cook'].includes(user.role)) {
      SessionStore.storeItem(SS_KEYS.TOKEN, token);
      SessionStore.storeItem(SS_KEYS.USER, JSON.stringify(user));
      dispatch(AUserLogin(token, user));
      dispatch(
        notify({
          title: 'Přihlášení uživatele',
          message: `Přihlášen uživatel: ${user.firstname} ${user.surname}`,
          status: 'success',
          dismissAfter: 3000,
        }),
      );
    } else {
      dispatch(
        notify({
          title: 'Přihlášení uživatele se nezdařilo',
          message: 'Špatné přihlašovací údaje',
          status: 'error',
          dismissAfter: 3000,
        }),
      );
    }
  } catch (e) {
    dispatch(AUserFailure(actionName, e));
    dispatch(
      notify({
        title: 'Přihlášení uživatele se nezdařilo',
        message: 'Špatné přihlašovací údaje',
        status: 'error',
        dismissAfter: 3000,
      }),
    );
  }
};

export const userLogin = (values) => async (dispatch) => {
  const actionName = 'userLogin';
  dispatch(AUserLoading(actionName));
  try {
    const response = await axios.post(`${ENDPOINTS.auth}/cardId`, { cardId: values.cardId, password: values.cardId });
    const {
      data: { token, user },
    } = response;
    if (user.role === 'user') {
      SessionStore.storeItem(SS_KEYS.TOKEN, token);
      SessionStore.storeItem(SS_KEYS.USER, JSON.stringify(user));
      dispatch(AUserLogin(token, user));
      dispatch(
        notify({
          title: 'Přihlášení uživatele',
          message: `Přihlášen uživatel: ${user.firstname} ${user.surname}\n(${user.cardId})`,
          status: 'success',
          dismissAfter: 3000,
        }),
      );
    } else {
      dispatch(
        notify({
          title: 'Přihlášení uživatele se nezdařilo',
          message: 'Špatné přihlašovací údaje',
          status: 'error',
          dismissAfter: 3000,
        }),
      );
    }
  } catch (e) {
    dispatch(
      notify({
        title: 'Přihlášení uživatele se nezdařilo',
        message: 'Špatné přihlašovací údaje',
        status: 'error',
        dismissAfter: 3000,
      }),
    );
    dispatch(AUserFailure(actionName, e));
  }
};

export const userLogout = () => (dispatch) => {
  SessionStore.clear();
  dispatch(AUserLogout());
};
