import * as Yup from 'yup';

const PriceListValidationSchema = Yup.object().shape({
  type: Yup.string().oneOf(['all', 'breakfast', 'lunch', 'dinner']),
  from: Yup.date().required('Datum od musí být vyplněno!'),
  price: Yup.number().required('Cena od musí být vyplněna!').min(0, 'Cena musí být kladná!'),
  reducedPrice: Yup.number(),
  to: Yup.date(),
});

export default PriceListValidationSchema;
