import { useSelector } from 'react-redux';
import { Button, Container, Form, FormGroup, InputGroup, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { remove as removeDiacritics } from 'diacritics';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons/faPlusCircle';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';

import { getFoodType } from '../../utils';

const FoodListContent = (props) => {
  const { type, handleAddFood } = props;

  const foods = useSelector((state) => state.admin.foods.items);

  const noPermanentFood = Object.values(foods).filter((f) => !f.permanent);
  const foodsArray = type === 'all' ? noPermanentFood : noPermanentFood.filter((food) => food.type === type);

  const [search, setSearch] = useState('');
  const [searchedFoods, setSearchedFoods] = useState(foodsArray);

  useEffect(() => {
    handleSearchFood();
  }, [foods, search]);

  const handleSearchFood = () => {
    const searchedText = removeDiacritics(search).toLowerCase();
    setSearchedFoods(foodsArray.filter((food) => removeDiacritics(food.longName).toLowerCase().includes(searchedText)));
  };

  const foodsSorted = searchedFoods.sort((a, b) => a.name.localeCompare(b.name));

  return (
    <Container fluid className="p-0 mt-3">
      <FormGroup>
        <Form.Label>Vyhledávání jídel:</Form.Label>
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text>
              <FontAwesomeIcon icon={faSearch} />
            </InputGroup.Text>
          </InputGroup.Prepend>
          <Form.Control
            placeholder="Vyhledejte jídlo"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                handleSearchFood();
              }
            }}
          />
          <InputGroup.Append>
            <Button type="button" onClick={handleSearchFood}>
              Hledat
            </Button>
          </InputGroup.Append>
        </InputGroup>
      </FormGroup>
      {foodsSorted && foodsSorted.length > 0 ? (
        <Table responsive>
          <thead>
            <tr>
              <th>Název jídla</th>
              {type === 'all' ? <th style={{ textAlign: 'center' }}>Typ</th> : null}
              <th />
            </tr>
          </thead>
          <tbody>
            {foodsSorted.map((food) => (
              <tr key={`food_${food.id}`}>
                <td>{food.name}</td>
                {type === 'all' ? <td style={{ textAlign: 'center' }}>{getFoodType(food.type)}</td> : null}
                <td>
                  <OverlayTrigger placement="top" overlay={<Tooltip id="addFood">Přidat jídlo</Tooltip>}>
                    <Button variant="success" onClick={() => handleAddFood(food)}>
                      <FontAwesomeIcon icon={faPlusCircle} />
                    </Button>
                  </OverlayTrigger>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <p>Vyhledávání neodpovídá žádné jídlo.</p>
      )}
    </Container>
  );
};

export default FoodListContent;
