export const __DEV__ = window.env.__DEV__;
export const __API_URL__ = window.env.__API_URL__;
export const KITCHEN_PASSWORD = window.env.__KITCHEN_PASSWORD__;
export const ORDER_HOUR_LIMIT = window.env.__ORDER_HOUR_LIMIT__;

const VERSION = 'v3';

export const ENDPOINTS = {
  allergens: `${__API_URL__}/${VERSION}/allergens`,
  auth: `${__API_URL__}/${VERSION}/auth`,
  credit: `${__API_URL__}/${VERSION}/credits`,
  foods: `${__API_URL__}/${VERSION}/foods`,
  menus: `${__API_URL__}/${VERSION}/menus`,
  orders: `${__API_URL__}/${VERSION}/orders`,
  ratings: `${__API_URL__}/${VERSION}/ratings`,
  users: `${__API_URL__}/${VERSION}/users`,
  creditLog: `${__API_URL__}/${VERSION}/creditLog`,
  priceList: `${__API_URL__}/${VERSION}/priceList`,
};
