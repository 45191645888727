import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Form, OverlayTrigger, Row, Table, Tooltip } from 'react-bootstrap';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import Files from 'react-files';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons/faPlusCircle';

import { getAllergens } from '../../redux/actions/allergensActions';
import { __API_URL__ } from '../../constants';
import { createFood, updateFood } from '../../redux/actions/foodsActions';

import { FoodValidationSchema } from './validation';

const FoodForm = (props) => {
  const { food, onSubmit } = props;

  const fileRef = useRef();

  const token = useSelector((state) => state.user.token);

  const loadingAllergens = useSelector((state) => state.admin.allergens.loadings.allergensAll);
  const allergens = useSelector((state) => state.admin.allergens.items);

  const loadingAdd = useSelector((state) => state.admin.foods.loadings.create);
  const loadingEdit = useSelector((state) => state.admin.foods.loadings.update);

  const dispatch = useDispatch();

  const [allergen, setAllergen] = useState();
  const [addedAllergens, setAddedAllergens] = useState([]);
  const [file, setFile] = useState();

  useEffect(() => {
    if (!loadingAllergens) {
      dispatch(getAllergens());
    }
  }, []);

  useEffect(() => {
    if (food) {
      setAddedAllergens(food.allergens);
    }
  }, [food]);

  const handleSubmit = (values) => {
    if (food) {
      if (values.type === 'lunch_dinner') {
        switch (food.type) {
          case 'breakfast':
            dispatch(createFood(token, { ...values, type: 'lunch' }));
            dispatch(createFood(token, { ...values, type: 'dinner' }));
            dispatch(updateFood(token, { ...values, disabled: true }));
            break;
          case 'lunch':
            dispatch(updateFood(token, food.id, { ...values, type: 'lunch' }));
            dispatch(createFood(token, { ...values, type: 'dinner' }));
            break;
          case 'dinner':
            dispatch(createFood(token, { ...values, type: 'lunch' }));
            dispatch(updateFood(token, food.id, { ...values, type: 'lunch' }));
            break;
        }
      } else {
        dispatch(updateFood(token, food.id, values));
      }
    } else {
      if (values.type === 'lunch_dinner') {
        dispatch(createFood(token, { ...values, type: 'lunch' }));
        dispatch(createFood(token, { ...values, type: 'dinner' }));
      } else {
        dispatch(createFood(token, values));
      }
    }
    onSubmit();
  };

  const initialValues = {
    name: food ? food.name : '',
    longName: food ? food.longName : '',
    type: food ? food.type : '',
    order: food ? food.order : undefined,
    ingredients: food ? food.ingredients : '',
    description: food ? food.description : '',
    price: '',
    reducedPrice: '',
    allergens: food ? food.allergens.map((a) => a.id) : [],
    permanent: food ? food.permanent : false,
    pictureBase64: undefined,
  };

  return (
    <Formik initialValues={initialValues} enableReinitialize validationSchema={FoodValidationSchema} onSubmit={handleSubmit}>
      {({ values, errors, touched, handleChange, setFieldValue, setFieldError, handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col xs={{ span: 5, offset: 1 }}>
              <h4>Informace o jídle</h4>
              <Form.Group>
                <Form.Label>Název *</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={values.name}
                  placeholder="Zadejte název"
                  onChange={handleChange}
                  isInvalid={touched.name && errors.name}
                />
                <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label>Celý název *</Form.Label>
                <Form.Control
                  type="text"
                  name="longName"
                  value={values.longName}
                  placeholder="Zadejte celý název"
                  onChange={handleChange}
                  isInvalid={touched.longName && errors.longName}
                />
                <Form.Control.Feedback type="invalid">{errors.longName}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label>Typ jídla *</Form.Label>
                <Form.Control
                  as="select"
                  name="type"
                  value={!values.type || values.type === '' ? 'Vyberte typ' : values.type}
                  placeholder="Vyberte typ"
                  onChange={(e) => {
                    handleChange(e);
                    setFieldValue('permanent', e.target.value === 'permanent');
                  }}
                  isInvalid={touched.type && errors.type}>
                  <option value="Vyberte typ" disabled>
                    Vyberte typ
                  </option>
                  <option value="permanent">stálá nabídka</option>
                  <option value="breakfast">snídaně</option>
                  <option value="lunch">oběd</option>
                  <option value="dinner">večeře</option>
                  <option value="lunch_dinner">oběd i večeře</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">{errors.type}</Form.Control.Feedback>
              </Form.Group>
              {values.permanent && (
                <Form.Group>
                  <Form.Label>Pořadí ve stálé nabídce</Form.Label>
                  <Form.Control type="number" name="order" value={values.order} placeholder="Zadejte pořadí" onChange={handleChange} />
                </Form.Group>
              )}
              <Form.Group>
                <Form.Label>Popis</Form.Label>
                <Form.Control as="textarea" name="description" value={values.description} placeholder="Zadejte popis" onChange={handleChange} />
              </Form.Group>
              <Form.Group>
                <Form.Label>Přísady</Form.Label>
                <Form.Control as="textarea" name="ingredients" value={values.ingredients} placeholder="Zadejte přísady" onChange={handleChange} />
              </Form.Group>
            </Col>
            <Col xs={{ span: 4, offset: 1 }}>
              {!food && (
                <>
                  <h4>Nastavení ceny</h4>
                  <Form.Group>
                    <Form.Label>Cena</Form.Label>
                    <Form.Control
                      type="number"
                      name="price"
                      value={values.price}
                      placeholder="Zadejte cenu"
                      onChange={handleChange}
                      isInvalid={touched.price && errors.price}
                    />
                    <Form.Control.Feedback type="invalid">{errors.price}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Zvýhodněná cena</Form.Label>
                    <Form.Control
                      type="number"
                      name="reducedPrice"
                      value={values.reducedPrice}
                      placeholder="Zadejte zvýhodněnou cenu"
                      onChange={handleChange}
                      isInvalid={touched.reducedPrice && errors.reducedPrice}
                    />
                    <Form.Control.Feedback type="invalid">{errors.reducedPrice}</Form.Control.Feedback>
                  </Form.Group>
                </>
              )}
              <h4>Alergeny</h4>
              <Form.Group>
                <Table responsive>
                  <tbody>
                    {addedAllergens.map((allergen, index) => (
                      <tr key={`allergen_key_${allergen.id ? allergen.id : allergen.id}`}>
                        <td>{allergen.name}</td>
                        <td>
                          <OverlayTrigger placement="right" overlay={<Tooltip id="removeAllergen">Odebrat alergen</Tooltip>}>
                            <Button
                              variant="danger"
                              onClick={() => {
                                const newAllergens = [...addedAllergens];
                                newAllergens.splice(index, 1);
                                setAddedAllergens(newAllergens);
                                setFieldValue(
                                  'allergens',
                                  newAllergens.map((a) => a.id),
                                );
                              }}>
                              <FontAwesomeIcon icon={faTrash} />
                            </Button>
                          </OverlayTrigger>
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td>
                        <Form.Control
                          as="select"
                          defaultValue="Vyberte alergen"
                          placeholder="Vyberte alergen"
                          onChange={(e) => setAllergen(allergens[e.target.value])}>
                          <option value="Vyberte alergen" disabled>
                            Vyberte alergen
                          </option>
                          {Object.values(allergens).map((allergen) => (
                            <option key={allergen.id} value={allergen.id}>
                              {allergen.name}
                            </option>
                          ))}
                        </Form.Control>
                      </td>
                      <td>
                        <OverlayTrigger placement="right" overlay={<Tooltip id="addAllergen">Přidat alergen</Tooltip>}>
                          <Button
                            variant="success"
                            onClick={() => {
                              if (allergen && addedAllergens.indexOf(allergen) === -1) {
                                const newAllergens = [...addedAllergens, allergen];
                                setAddedAllergens(newAllergens);
                                setFieldValue(
                                  'allergens',
                                  newAllergens.map((a) => a.id),
                                );
                              }
                            }}>
                            <FontAwesomeIcon icon={faPlusCircle} />
                          </Button>
                        </OverlayTrigger>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Form.Group>
              <h4>Obrázek</h4>
              <Form.Group>
                <Files
                  ref={fileRef}
                  className="files-dropzone-gallery-single"
                  accepts={['image/png']}
                  multiple={false}
                  clickable
                  onChange={(files) => {
                    if (files && files.length !== 0) {
                      const file = files[0];
                      const img = new Image();
                      img.onload = () => {
                        const reader = new FileReader();
                        reader.onload = () => {
                          setFieldValue('pictureBase64', reader.result);
                          setFieldValue('pictureBase64', reader.result);
                          setFile(file);
                        };
                        reader.readAsDataURL(file);
                      };
                      const _URL = window.URL || window.webkitURL;
                      img.src = _URL.createObjectURL(file);
                    }
                  }}>
                  {file ? (
                    <div className="files-gallery-single">
                      <img key={file.id} className="files-gallery-single-item" src={file.preview.url} alt="" />
                    </div>
                  ) : values.picture ? (
                    <img className="files-gallery-single-item" src={`${__API_URL__}/${food.picture}`} alt="" />
                  ) : (
                    <div>Zde vložte obrázek</div>
                  )}
                </Files>
                <Button
                  type="button"
                  variant="danger"
                  onClick={() => {
                    setFile(undefined);
                    setFieldError('pictureBase64', 'Musíte vložit obrázek!');
                  }}>
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-end">
              <Button variant="outline-dark" type="submit" disabled={loadingAdd || loadingEdit}>
                {food ? 'Upravit jídlo' : 'Přidat jídlo'}
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default FoodForm;
