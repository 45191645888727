import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { faHandPointLeft } from '@fortawesome/free-solid-svg-icons/faHandPointLeft';
import { Col, Table } from 'react-bootstrap';
import moment from 'moment';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons/faPlusCircle';

import GridSection from '../../../components/Section/GridSection';
import MyButton from '../../../components/MyButton/index';
import Section from '../../../components/Section/Section';
import Loading from '../../../components/Loading/index';
import { getPriceListItem } from '../../../redux/actions/priceListActions';
import { getFoodType } from '../../../utils';

const PriceListPreview = () => {
  const match = useRouteMatch();
  const history = useHistory();

  const token = useSelector((state) => state.user.token);
  const loading = useSelector((state) => state.admin.priceList.loadings.priceListOne);
  const loadingRemove = useSelector((state) => state.admin.priceList.loadings.priceListRemove);
  const errorRemove = useSelector((state) => state.admin.priceList.errors.priceListRemove);
  const priceListItem = useSelector((state) => state.admin.priceList.items[match.params.id]);

  const dispatch = useDispatch();

  const [isRemoved, setIsRemoved] = useState(false);

  useEffect(() => {
    if (!loading) {
      dispatch(getPriceListItem(token, match.params.id));
    }
  }, []);

  useEffect(() => {
    if (isRemoved && !loadingRemove && !errorRemove) {
      setIsRemoved(false);
      history.goBack();
    }
  }, [loadingRemove, errorRemove]);

  if (!priceListItem) return null;

  return (
    <div>
      <GridSection heading="ADMINISTRACE">
        <MyButton link="/admin/priceList" variant="outline-dark" icon={faHandPointLeft} text="ZPĚT" />
        <MyButton link={`/admin/priceList/edit/${priceListItem.id}`} variant="success" icon={faPlusCircle} text="PŘIDAT NOVOU CENU" />
      </GridSection>
      <Section heading="Náhled cen">
        {loading ? (
          <Loading />
        ) : (
          <Col xs={{ span: 8, offset: 2 }}>
            <Table responsive striped>
              <thead>
                <tr>
                  <th>Jídlo</th>
                  <th>Typ</th>
                  <th>Od</th>
                  <th>Do</th>
                  <th style={{ textAlign: 'center' }}>Cena</th>
                  <th style={{ textAlign: 'center' }}>Zvýhodněná cena</th>
                </tr>
              </thead>
              <tbody>
                {priceListItem.priceList.map((p) => (
                  <tr key={`priceListItem_${p.id}`}>
                    <td>{priceListItem.name}</td>
                    <td>{getFoodType(p.type)}</td>
                    <td>{p.from ? moment.utc(p.from).format('DD. MM. YYYY') : '-'}</td>
                    <td>{p.to ? moment.utc(p.to).format('DD. MM. YYYY') : '-'}</td>
                    <td style={{ textAlign: 'center' }}>{p.price}</td>
                    <td style={{ textAlign: 'center' }}>{p.reducedPrice}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        )}
      </Section>
    </div>
  );
};

export default PriceListPreview;
