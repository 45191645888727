import { combineReducers } from 'redux';

import allergensReducer from './allergensReducer';
import foodsReducer from './foodsReducer';
import menusReducer from './menusReducer';
import ordersReducer from './ordersReducer';
import ratingsReducer from './ratingsReducer';
import usersReducer from './usersReducer';
import priceListReducer from './priceListReducer';

const adminReducer = combineReducers({
  allergens: allergensReducer,
  foods: foodsReducer,
  menus: menusReducer,
  orders: ordersReducer,
  priceList: priceListReducer,
  ratings: ratingsReducer,
  users: usersReducer,
});

export default adminReducer;
