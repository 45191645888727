import React from 'react';

import PriceListTableAll from './PriceListTableAll';
import PriceListTablePermanent from './PriceListTablePermanent';
import PriceListTableByType from './PriceListTableByType';

const PriceListTable = (props) => {
  const { type } = props;

  switch (type) {
    case 'all':
      return <PriceListTableAll />;
    case 'permanent':
      return <PriceListTablePermanent />;
    default:
      return <PriceListTableByType type={type} />;
  }
};

export default PriceListTable;
