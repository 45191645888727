import React, { useEffect, useState } from 'react';
import { faHandPointLeft } from '@fortawesome/free-solid-svg-icons/faHandPointLeft';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import GridSection from '../../../components/Section/GridSection';
import Section from '../../../components/Section/Section';
import MyButton from '../../../components/MyButton/index';
import PriceListItemFormMultiple from '../../../forms/PriceListForms/PriceListMultipleForm';

const PriceListForms = () => {
  const history = useHistory();

  const loading = useSelector((state) => state.admin.priceList.loadings.priceListCreateMultiple);
  const error = useSelector((state) => state.admin.priceList.errors.priceListCreateMultiple);

  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    if (isSubmitted && !loading && !error) {
      setIsSubmitted(false);
      history.replace('/admin/priceList');
    }
  }, [loading, error]);

  return (
    <div>
      <GridSection heading="ADMINISTRACE">
        <MyButton variant="outline-dark" icon={faHandPointLeft} text="ZPĚT" action={() => history.goBack()} />
      </GridSection>
      <Section heading="Formulář pro hromadnou úpravu ceníku">
        <PriceListItemFormMultiple buttonText="Upravit ceník" onSubmit={() => setIsSubmitted(true)} />
      </Section>
    </div>
  );
};

export default PriceListForms;
