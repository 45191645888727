import React, { useEffect, useMemo, useState } from 'react';
import { Button, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import { remove as removeDiacritics } from 'diacritics';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import moment from 'moment/moment';

import FoodFilter from '../Filters/FoodFilter';
import { getFoodType } from '../../utils';

const PriceListTableContent = (props) => {
  const { priceList, showType } = props;

  const history = useHistory();

  const priceListItems = useMemo(
    () =>
      Object.values(priceList)
        .map((p) => {
          const priceListActual = p.priceList.find(
            (p) => (new Date(p.from) <= new Date() && new Date() <= new Date(p.to)) || (new Date(p.from) <= new Date() && !p.to),
          );
          return {
            ...p,
            from: priceListActual?.from ? moment.utc(priceListActual?.from).format('DD. MM. YYYY') : '-',
            to: priceListActual?.to ? moment.utc(priceListActual?.to).format('DD. MM. YYYY') : '-',
            price: priceListActual?.price || '-',
            reducedPrice: priceListActual?.reducedPrice || '-',
          };
        })
        .sort((a, b) => a.name.localeCompare(b.name)),
    [priceList],
  );

  const [filteredPriceListItems, setFilteredPriceListItems] = useState(priceListItems);

  useEffect(() => {
    handleFilter('');
  }, [priceList]);

  const handleFilter = (search) => {
    const filtered = priceListItems.filter((food) => {
      return removeDiacritics(food.name).toLowerCase().includes(removeDiacritics(search).toLowerCase());
    });
    setFilteredPriceListItems(filtered);
  };

  const tooltipView = <Tooltip id="tooltip-view">Zobrazit položku ceníku</Tooltip>;

  return (
    <>
      <FoodFilter handleFilter={handleFilter} />
      <Table responsive striped>
        <thead>
          <tr>
            <th>Jídlo</th>
            {showType ? <th>Typ</th> : null}
            <th>Od</th>
            <th>Do</th>
            <th style={{ textAlign: 'center' }}>Cena</th>
            <th style={{ textAlign: 'center' }}>Zvýhodněná cena</th>
            <th style={{ textAlign: 'right' }}>Možnosti</th>
          </tr>
        </thead>
        <tbody>
          {filteredPriceListItems.map((priceListItem) => (
            <tr key={`priceListItem_${priceListItem.id}`}>
              <td>{priceListItem.name}</td>
              {showType ? <td>{getFoodType(priceListItem.type)}</td> : null}
              <td>{priceListItem.from}</td>
              <td>{priceListItem.to}</td>
              <td style={{ textAlign: 'center' }}>{priceListItem.price}</td>
              <td style={{ textAlign: 'center' }}>{priceListItem.reducedPrice}</td>
              <td style={{ textAlign: 'right' }}>
                <OverlayTrigger placement="top" overlay={tooltipView}>
                  <Button variant="light" onClick={() => history.push(`/admin/priceList/preview/${priceListItem.id}`)}>
                    <FontAwesomeIcon icon={faEye} />
                  </Button>
                </OverlayTrigger>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default PriceListTableContent;
