import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

import AdminLogin from './Login';
import Allergens from './Allergens';
import Home from './Home';
import Foods from './Foods';
import Menus from './Menus';
import Orders from './Orders';
import Ratings from './Ratings';
import Users from './Users';
import CreditLog from './CreditLog';
import PriceList from './PriceList';

const Admin = () => {
  const token = useSelector((state) => state.user.token);
  const user = useSelector((state) => state.user.user);

  return (
    <div id="admin">
      {token && ['admin', 'cook'].includes(user.role) ? (
        <Switch>
          <Route path="/admin/allergens" component={Allergens} />
          <Route path="/admin/foods" component={Foods} />
          <Route path="/admin/menus" component={Menus} />
          <Route path="/admin/orders" component={Orders} />
          <Route path="/admin/ratings" component={Ratings} />
          <Route path="/admin/users" component={Users} />
          <Route path="/admin/creditLog" component={CreditLog} />
          <Route path="/admin/priceList" component={PriceList} />
          <Route path="/admin" component={Home} />
        </Switch>
      ) : (
        <Route path="/admin" component={() => <AdminLogin />} />
      )}
    </div>
  );
};

export default withRouter(Admin);
