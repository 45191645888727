import React from 'react';
import { Route, Switch } from 'react-router-dom';

import PriceListContainer from './PriceList';
import PriceListForms from './PriceListForms';
import PriceListPreview from './PriceListPreview';
import PriceListFormMultiple from './PriceListFormMultiple';

const PriceList = () => {
  return (
    <Switch>
      <Route path="/admin/priceList/add" component={PriceListForms} />
      <Route path="/admin/priceList/addMultiple" component={PriceListFormMultiple} />
      <Route path="/admin/priceList/edit/:id" component={PriceListForms} />
      <Route path="/admin/priceList/preview/:id" component={PriceListPreview} />
      <Route path="/admin/priceList" component={PriceListContainer} />
    </Switch>
  );
};

export default PriceList;
