const getFoodPrice = (foodId, orderDate, priceList, packagingId, takeout) => {
  if (!priceList || !packagingId) {
    return NaN;
  }
  const price =
    priceList[foodId]?.priceList.find((p) => new Date(p.from) <= new Date(orderDate) && (new Date(orderDate) <= new Date(p.to) || !p.to))?.price || 0;
  const packagingPrice =
    priceList[packagingId]?.priceList.find((p) => new Date(p.from) <= new Date(orderDate) && (new Date(orderDate) <= new Date(p.to) || !p.to))?.price || 0;
  return price + (takeout ? packagingPrice : 0);
};

const getFoodReducedPrice = (foodId, orderDate, priceList, packagingId, takeout) => {
  if (!priceList || !packagingId) {
    return NaN;
  }
  const price =
    priceList[foodId]?.priceList.find((p) => new Date(p.from) <= new Date(orderDate) && (new Date(orderDate) <= new Date(p.to) || !p.to))?.reducedPrice || 0;
  const packagingPrice =
    priceList[packagingId]?.priceList.find((p) => new Date(p.from) <= new Date(orderDate) && (new Date(orderDate) <= new Date(p.to) || !p.to))?.reducedPrice ||
    0;
  return price + (takeout ? packagingPrice : 0);
};

export { getFoodPrice, getFoodReducedPrice };
