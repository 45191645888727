import { PRICELIST_ALL, PRICELIST_FAILURE, PRICELIST_LOADING, PRICELIST_ONE } from '../types/priceListTypes';

const INITIAL_STATE = {
  loadings: {},
  errors: {},
  items: {},
  packagingId: undefined,
};

const priceListReducer = (currentState = INITIAL_STATE, action) => {
  switch (action.type) {
    case PRICELIST_LOADING:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          [action.name]: true,
        },
      };
    case PRICELIST_FAILURE:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          [action.name]: false,
        },
        errors: {
          ...currentState.errors,
          [action.name]: action.error,
        },
      };
    case PRICELIST_ALL:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          priceListAll: false,
        },
        errors: {
          ...currentState.errors,
          priceListAll: undefined,
        },
        items: {
          ...currentState.items,
          ...action.priceList,
        },
        packagingId: Object.values(action.priceList).find((f) => f.name === 'Box')?.id || undefined,
      };
    case PRICELIST_ONE:
      return {
        ...currentState,
        loadings: {
          ...currentState.loadings,
          priceListOne: false,
        },
        errors: {
          ...currentState.errors,
          priceListOne: undefined,
        },
        items: {
          ...currentState.items,
          [action.priceListItem.id]: {
            ...currentState.items[action.priceListItem.id],
            ...action.priceListItem,
          },
        },
      };
    default:
      return currentState;
  }
};

export default priceListReducer;
