import React from 'react';
import { useSelector } from 'react-redux';

import PriceListTableContent from './PriceListTableContent';

const PriceListTableAll = () => {
  const priceList = useSelector((state) => state.admin.priceList.items);

  const priceListSorted = Object.values(priceList).sort((a, b) => a.name.localeCompare(b.name));

  return <PriceListTableContent showType priceList={priceListSorted} />;
};

export default PriceListTableAll;
