import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { faHandPointLeft } from '@fortawesome/free-solid-svg-icons/faHandPointLeft';

import GridSection from '../../../components/Section/GridSection';
import Section from '../../../components/Section/Section';
import MyButton from '../../../components/MyButton/index';
import MenuForm from '../../../forms/MenuForms/MenuForm';
import { getMenu } from '../../../redux/actions/menusActions';

import 'react-datepicker/dist/react-datepicker.css';

const MenuForms = () => {
  const match = useRouteMatch();
  const history = useHistory();

  const loading = useSelector((state) => state.admin.menus.loadings.one);
  const menu = useSelector((state) => state.admin.menus.items[match.params.id]);
  const loadingAdd = useSelector((state) => state.admin.menus.loadings.create);
  const errorAdd = useSelector((state) => state.admin.menus.errors.create);
  const loadingEdit = useSelector((state) => state.admin.menus.loadings.update);
  const errorEdit = useSelector((state) => state.admin.menus.errors.update);

  const dispatch = useDispatch();

  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    if (match.params.id && !loading) {
      dispatch(getMenu(match.params.id));
    }
  }, []);

  useEffect(() => {
    if (isSubmitted && !loadingAdd && !errorAdd) {
      setIsSubmitted(false);
      history.replace('/admin/menus');
    }
  }, [loadingAdd, errorAdd]);

  useEffect(() => {
    if (isSubmitted && !loadingEdit && !errorEdit) {
      setIsSubmitted(false);
      history.replace('/admin/menus');
    }
  }, [loadingEdit, errorEdit]);

  return (
    <div>
      <GridSection heading="ADMINISTRACE">
        <MyButton link="/admin/menus" variant="outline-dark" icon={faHandPointLeft} text="ZPĚT" />
      </GridSection>
      <Section heading={menu ? 'FORMULÁŘ PRO ÚPRAVU MENU' : 'FORMULÁŘ PRO PŘIDÁNÍ MENU'}>
        <MenuForm menu={menu} onSubmit={() => setIsSubmitted(true)} />
      </Section>
    </div>
  );
};

export default MenuForms;
